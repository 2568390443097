import React from 'react';
import { BounceLoader } from 'react-spinners';
import './App.css';

function App() {
  return (
    <div className="content">
      <div className="growing">Growing in progress...</div>
      <BounceLoader />
    </div>
  );
}

export default App;
